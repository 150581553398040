<template>
  <div class="MyGame" :style="'height:' + Height + 'px'">
    <Nav title="我的比赛"></Nav>
    <div class="home">
      <div class="LatestCompeti">
        <div
          class="cmpt-item"
          v-for="(item, index) in MatchListAll"
          :key="index"
        >
          <div class="top">
            <div class="left">
              <h2>{{ item.challenge_title }}</h2>
              <div class="attr">
                <div
                  class="attr-left"
                  :style="
                    item.challenge_status == 0
                      ? 'color: #2b67ee;'
                      : 'color:red;'
                  "
                >
                  {{
                    item.challenge_status == 0
                      ? "进行中"
                      : item.challenge_status == 1
                      ? "已结束"
                      : "已暂停"
                  }}
                </div>
              </div>
            </div>
            <div class="right">
              <!-- <img :src="item.code_url" alt="" /> -->
			  {{item.max_score}}
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <div>开始时间：{{ item.start_time }}</div>
              <div>结束时间：{{ item.en_time }}</div>
            </div>
            <div class="right" @click="Jump(item)">
              <!-- {{ item.challenge_status == 0 ? "立即参加" : "参看比赛" }} -->
			  参看排名
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/H5/navigation.vue";
import { entry_list } from "../../api/ThisInfo.js";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      Height: document.documentElement.clientHeight,
      MatchListAll: [],
    };
  },
  created() {
    this.getEntryList();
  },
  methods: {
    getEntryList() {
      entry_list().then((res) => {
        if (res.code == 200) {
          this.MatchListAll = res.data;
        }
      });
    },
	Jump(item){
		this.$router.push({
        path: "/SingleItemH5",
        query: {
          id: item.challenge_id,
          state: 1,
          matchstate: item.challenge_status,
        },
      });
	},
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: calc(100% - 20px);
  height: calc(100% - 54px);
  overflow-y: auto;
  margin: 0 auto;
  margin-top: 44px;
  padding-top: 10px;
  box-sizing: border-box;
  .LatestCompeti {
    .cmpt-item {
      width: 100%;
      height: 148px;
      background: #fff;
      border-radius: 0.37333rem;
      margin-top: 0.21333rem;
      padding: 0.8rem 0.5rem 0 0.5rem;
      margin-bottom: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.37333rem;
        border-bottom: 0.01333rem solid #f3f3f4;
        margin-bottom: 0.18667rem;
        .left {
          height: 76px;
          position: relative;
          width: 60%;
          h2 {
            height: 56px;
            font-size: 1rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #232526;
            line-height: 1.1rem;
            margin: 0;
            padding: 0;
          }
          .attr {
            height: 18px;
            width: 100%;
            display: flex;
            .attr-left {
              font-size: 1rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 18px;
              width: 100%;
              color: #ff7b60;
            }
          }
        }
        .right {
          width: 36%;
          height: 76px;
          border-radius: 10px;
          overflow: hidden;

		  text-align: center;
		line-height: 76px;
		font-size: 20px;
    color: red;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        .left {
          width: 60%;
          height: 40px;
          div {
            width: 100%;
            height: 20px;
            line-height: 20px;
          }
        }
        .right {
          width: 30%;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(90deg, #5383f6, #3b6ee7);
          box-shadow: 0 0.10667rem 0.16rem 0 rgb(58 109 230 / 25%),
            0 0 0.02667rem 0 hsl(0deg 0% 100% / 50%);
          border-radius: 0.50667rem;
          font-size: 1rem;
          color: #fff;
        }
      }
    }
  }
}
</style>